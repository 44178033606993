import axios from "axios"
export class RelatorioEstoque {
  constructor() {
    this.urlServico = process.env.VUE_APP_URL_VOLUPIA + `/api/Produto`
  }
  gerarRelatorioEstoquePorLinha() {
    let config = {
      withCredentials: false,
      crossdomain: true
    }

    return axios({
      url: this.urlServico + `/GerarRelatorioEstoquePorLinha`,
      method: "GET",
      responseType: "blob",
      ...config // important
    })
  }
  gerarRelatorioEstoquePorReferencia() {
    let config = {
      withCredentials: false,
      crossdomain: true
    }

    return axios({
      url: this.urlServico + `/GerarRelatorioEstoquePorReferencia`,
      method: "GET",
      responseType: "blob",
      ...config // important
    })
  }
}
