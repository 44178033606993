<template>
  <v-form v-model="valid" ref="form">
    <div class="form-btn">
      <v-btn outline @click="gerarRelatorioEstoquePorLinha" color="primary">Gerar relatório por LINHA</v-btn>
      <v-btn outline @click="gerarRelatorioEstoquePorReferencia" color="primary">Gerar relatório por REFERÊNCIA</v-btn>
      <v-btn @click="voltar" outline>Voltar</v-btn>
    </div>
    <v-snackbar :color="'pink accent-1'" v-model="snackbar" :top="true">
      {{ text }}
    </v-snackbar>
  </v-form>
</template>
<script>
import { serverBus } from "../../../main"
import { RelatorioEstoque } from "../../../servicos/relatorios/relatorioEstoque"
const servicoRelatorioEstoque = new RelatorioEstoque()
export default {
  // eslint-disable-next-line
  props: { origem: String },
  data() {
    return {
      snackbar: false,
      text: "Gerando relatório de estoque. Aguarde!"
    }
  },
  created() {
    serverBus.$on("romaneioSelected", value => {
      this.codRomaneio = value.Id
    })
  },
  methods: {
    gerarRelatorioEstoquePorLinha() {
      this.snackbar = true
      servicoRelatorioEstoque.gerarRelatorioEstoquePorLinha().then(
        response => {
          var blob = new Blob([response.data], { type: "application/pdf" })
          var link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          var dataRelatorio = new Date()
          var fileName = `Estoque_por_linha_${dataRelatorio}.xlsx`
          link.download = fileName
          link.click()
          this.snackbar = false
        },
        err => {
          this.snackbar = true
          this.text = err.response.data[0].detail
        }
      )
    },
    gerarRelatorioEstoquePorReferencia() {
      this.snackbar = true
      servicoRelatorioEstoque.gerarRelatorioEstoquePorReferencia().then(
        response => {
          var blob = new Blob([response.data], { type: "application/pdf" })
          var link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          var dataRelatorio = new Date()
          var fileName = `Estoque_por_referencia_${dataRelatorio}.xlsx`
          link.download = fileName
          link.click()
          this.snackbar = false
        },
        err => {
          this.snackbar = true
          this.text = err.response.data[0].detail
        }
      )
    },
    voltar() {
      this.$router.push({ name: "Relatorios" })
    }
  }
}
</script>
