<template>
  <div id="pageCadastroPraca">
    <v-container grid-list-x2 fluid>
      <v-layout row wrap justify-center>
        <v-flex lg6 sm12 xs12>
          <v-card>
            <v-toolbar color="pink" dark flat dense cad>
              <v-toolbar-title class="subheading">Estoque</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="">
              <estoque></estoque>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import Estoque from "@/components/relatorios/estoque/estoquePorLinhaOuReferencia"
export default {
  components: {
    Estoque
  }
}
</script>
